.main-logo {
    position: absolute;
    top: 2em;
    left: 50%;
    @include transform(translateX(-50%));

    @media screen and (max-width: 991px) {
        position: static;
        padding: 2.8em 15px;
        @include transform(none);
        text-align: center;
    }

    &__link {
        display: inline-block;
        vertical-align: top;
        text-align: center;
    }

    &__img {
        font-size: 4.25em;
        line-height: 1;
        vertical-align: top;
        color: $color-header-text-hover;
    }

    &__text {
        display: inline-block;
        margin-top: 1em;
        font-size: 1.22222222em;
        letter-spacing: 3px;
        font-weight: 700;
        color: $color-header-text;
        text-transform: uppercase;
    }

}