.bot-protection {

    .form-group {
        float: left;
        width: calc(100% - 12em);
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            margin-bottom: 1em;
        }
    }

    .protection {
        float: right;
        width: 12em;
        padding-left: 1em;
        padding-top: 2em;
        text-align: right;
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            text-align: center;
            padding-left: 0;
            padding-top: 0;
        }
        > span {
            display: inline-block;
            vertical-align: middle;
            &.code {
                padding-top: .5em;
            }
            &.btn-update {
                font-size: 2em;
                line-height: .6;
                color: $color-text-blue;
                cursor: pointer;
            }
        }
    }

}