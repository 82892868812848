.cards-list {
    //margin-bottom: 1.75em;
    + .btn-wrap {
        margin-bottom: 3.5em;
    }
}

.card {
    margin-bottom: 3.75em;

    @media screen and (max-width: 991px) {
        max-width: 560px;
    }

    &__media {
        position: relative;
        width: 100%;
        margin-bottom: .8em;
        background-color: $bg-default-card-img;
        &_default {
            .card__img-wrap {
                &::after {
                    content: none;
                }
            }
            .card__img-link {
                text-align: right;
            }
        }
        &_gallery {
            .card__img-link {
                .icons {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%,-50%));
                    padding: 0;
                    font-size: 2.5em;
                    opacity: .7;
                }
            }
        }
    }

    &__img-wrap {
        position: relative;
        width: 100%;
        padding-top: 52%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 40%;
            position: absolute;
            z-index: 9;
            bottom: 0;
            left: 0;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.6+75 */
            background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 75%, rgba(0,0,0,0.6) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 75%,rgba(0,0,0,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 75%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); /* IE6-9 */

        }
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 0;
            opacity: 0;
        }
    }

    &__img-link {
        display: inline-block;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .icons {
            padding: 10px 20px 0 0;
            font-size: 4em;
            color: $color-text-img;
            opacity: .4;
        }
    }

    &__media-text {
        position: absolute;
        z-index: 10;
        width: 100%;
        left: 0;
        bottom: 10%;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: .6em;
        color: $color-text-img;
        .icons {
            margin-right: .5em;
            opacity: .8;
        }
        .text {
            display: inline-block;
            vertical-align: middle;
            &.bottom-line {
                &::after {
                    width: 5em;
                    bottom: -.6em;
                    background-color: $color-yellow;
                }
            }
        }
        .warn-text {
            float: right;
            display: inline-block;
            vertical-align: middle;
            color: $color-remark;
            > * {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &__title {
        max-height: 3.75em;
        overflow: hidden;
        @media screen and (max-width: 991px) {
            font-size: 1.125em;
        }
    }

    &__title-link {
        font-weight: 500;
        color: $color-text-blue;
        &:hover {
            color: $color-text-blue;
            text-decoration: underline;
        }
    }

    &__title-text {

    }

    &__remark-text {
        color: $color-remark;
        .icons {
            display: inline-block;
            vertical-align: bottom;
            position: relative;
            top: -.125em;
        }
    }

}