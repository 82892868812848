.table-side {
    font-size: 1em;
    margin-bottom: 2em;

    &__wrap {

    }

    .table {
        width: 100%;
        margin-bottom: 1.5em;
        font-size: .875em;
        @media screen and (max-width: 1280px) {
            font-size: .875em;
        }
        @media screen and (max-width: 1140px) {
            font-size: .75em;
        }
        @media screen and (max-width: 991px) {
            font-size: .875em;
        }
        thead {
            tr {
                th.table-side__title {
                    vertical-align: middle;
                    width: 100%;
                    height: 3.875em;
                    padding: 0 10px;
                    background-color: $bg-grey;
                    text-align: center;
                    font-size: 1.14285714em;
                    font-weight: 500;
                    color: $color-text-base;
                    > span {
                        display: inline-block;
                        vertical-align: top;
                        width: 100%;
                    }
                    .small-text {
                        font-size: .875em;
                    }
                }
                &:last-child {
                    border-top: 1px solid $table-border;
                    border-bottom: 1px solid $table-border;
                }
            }
        }
        tbody {
            tr {
                td {
                    white-space: nowrap;
                }
            }
        }
        &__striped {
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: $bg-table-strip-odd;
                    }
                }
            }
        }
    }

    .btn-wrap {
        text-align: right;
        .btn-link {
            padding-right: 1.4em;
            .icons {
                font-size: .7em;
            }
        }
    }

}