.text-posts {

    &__list {
        margin-bottom: 3.5em;
    }

    &__item {
        margin-bottom: 2.5em;
    }

    &__type {
        @extend %date;
    }

    &__title {
        max-height: 2.5em;
        margin: .1em 0 .5em 0;
        color: $color-text-blue;
        font-weight: 500;
        overflow: hidden;
    }

    &__link {
        &:hover {
            text-decoration: underline;
        }
    }

    &__date {
        @extend %date;
    }

}