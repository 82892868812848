.breadcrumbs {
    padding: 1.5em 0;
    font-size: 1em;

    @media screen and (max-width: 991px) {
        display: none;
    }

    &__list {

    }

    &__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-right: 2em;
        color: $color-text-grey;
        font-weight: 500;
        &::after {
            content: '/';
            display: block;
            position: absolute;
            top: 50%;
            right: 1em;
            @include transform(translate(70%, -50%));
            color: $color-text-grey;
        }
        &:first-child {
            color: $color-text-blue;
        }
        &:last-child {
            padding-right: 0;
            &::after {
                content: none;
            }
        }
        a, span {

        }
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

}