
.main-header {
    .top-control {
        position: relative;
        padding: 0 2.5em;
        margin-bottom: .5em;
        //color: $color-text-img;

        @media screen and (max-width: 1199px) {
            padding: 0 1.5em;
        }
        @media screen and (max-width: 991px) {
            padding: 1em 15px;
            margin-bottom: 0;
            border-bottom: 1px solid $border-top-menu-mobile;
            background-color: $bg-header-mobile;
        }

        .left-col {
            float: left;
            .burger, .search-btn-mob {
                display: none;
                vertical-align: top;

                @media screen and (max-width: 991px) {
                    display: inline-block;
                }
            }
            .burger {
                margin-right: 4em;
                @media screen and (max-width: 767px) {
                    margin-right: 2em;
                }
                @media screen and (max-width: 460px) {
                    margin-right: 1.5em;
                }
            }
            .search-btn-mob {
                font-size: 1.8em;
                line-height: 1;
                cursor: pointer;
                .icons {
                    vertical-align: top;
                }
            }
        }

        .right-col {
            float: right;
            .btn-link {
                display: block;
                @media screen and (max-width: 991px) {
                    float: left;
                    margin-right: 4.5em;
                }
                @media screen and (max-width: 767px) {
                    margin-right: 1.5em;
                }
                &.defect {
                    margin-top: 0;
                    margin-bottom: 5em;
                    color: $color-header-text;

                    @media screen and (max-width: 991px) {
                        float: right;
                        margin-bottom: 0;
                        margin-right: 0;
                    }
                    @media screen and (max-width: 460px) {
                        padding-left: 0;
                        font-size: 1.25em;
                    }
                    .icons {
                        @media screen and (max-width: 460px) {
                            position: static;
                            @include transform(none);
                        }
                    }
                    .text {
                        @media screen and (max-width: 460px) {
                            display: none;
                        }
                    }
                    &:hover {
                        color: $color-header-text-hover;
                    }
                }
            }
        }

    }

    .btn-link {
        margin: .4em 0;
        //font-size: 1.125em;
        font-weight: 500;
        color: $color-header-text-secondary;
        &:hover {
            color: $color-header-text-hover;
        }
    }

    .gov-link {
        position: relative;
        padding-left: 1.2em;
        &:hover {
            color: $color-header-text-hover;
            .descr {
                color: $color-header-text-hover;
            }
        }
        &::before, &::after {
            content: '';
            display: block;
            width: .65em;
            height: 50%;
            position: absolute;
            left: 0;
        }
        &::before {
            top: 0;
            background-color: #0069e7;
        }
        &::after {
            bottom: 0;
            background-color: #ffe449;
        }
        > a {
            display: inline-block;
            vertical-align: top;
            line-height: 1;
            > span {
                display: inline-block;
                vertical-align: top;
            }
        }
        .site {
            margin-bottom: .3em;
            font-size: 1.33333333em;
            line-height: .8;
            font-weight: 700;
        }
        .descr {
            padding-bottom: .3em;
            font-size: .66666666em;
            line-height: 1.2;
            color: $color-gov-link-sub-text;
            @include transition(all, .2s, ease-in-out);
        }
    }

    .font-size-wrap {
        margin-bottom: 4em;
        font-weight: 500;
        @media screen and (max-width: 991px) {
            float: right;
            margin-bottom: 0;
            margin-right: 0;
        }
        @media screen and (max-width: 460px) {
            margin-top: .2em;
            padding-left: 0;
            //font-size: 1.25em;
        }
        .btn-link.standart {
            margin-top: .5em;
            margin-bottom: 0;
            margin-right: 0;
            float: none;
            .text {
                @media screen and (max-width: 991px) {
                    display: none;
                }
            }
            .icons {
                display: none;
                position: static;
                @include transform(none);
                @media screen and (max-width: 991px) {
                    display: inline-block;
                }
            }
            @media screen and (max-width: 991px) {
                position: absolute;
                width: 2.5em;
                height: 2em;
                top: 100%;
                right: 15px;
                margin-top: 0;
                text-align: right;
                line-height: 1.5;
            }
        }
        .font-size-link {
            font-weight: 700;
            margin-right: 15px;
            &:focus {
                border: 1px solid $color-text-base;
            }
            &:last-child {
                margin-right: 0;
            }
            &[data-fontsize="16"] {
                font-size: .88888889em;
            }
            &[data-fontsize="18"] {
                font-size: 1em;
            }
            &[data-fontsize="20"] {
                font-size: 1.11111111em;
                @media screen and (max-width: 991px) {
                    margin-right: 0;
                }
            }
            &.active-text {
                text-decoration: underline;
                cursor: auto;
            }
        }
    }

}