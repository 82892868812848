.twitter-widget {
    position: absolute;
    z-index: 101;
    width: auto;
    height: 100%;
    top: 0;
    right: 0;
    @include transform(translateX(100%));
    @include transition(all, .3s, ease-in-out);
    @media screen and (max-width: 991px) {
        height: 90%;
        top: 24%;
        z-index: 99;
    }
    @media screen and (max-width: 460px) {
        top: 68px;
        height: 92%;
    }

    &.open {
        @include transform(none);
        z-index: 101;
        .twitter-widget__visible {
            .icon-wrap {
                @media screen and (max-width: 991px) {
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

    &.visible-index {
        z-index: 1000;
        .twitter-widget__visible {
            .icon-wrap {
                @media screen and (max-width: 991px) {
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

    &__visible {
        position: absolute;
        width: 55px;
        height: 100%;
        top: 0;
        right: 100%;

        background-color: $bg-grey;

        color: #104d82;
        text-align: center;

        cursor: pointer;

        @media screen and (max-width: 1199px) {
            width: 45px;
        }

        .visible-text {
            display: inline-block;
            vertical-align: top;
            height: 80%;
            writing-mode: tb-rl;
            transform: rotate(-180deg);
            font-size: .75em;
            letter-spacing: 1px;
            line-height: 55px;
            white-space: nowrap;
            text-transform: uppercase;
            @media screen and (max-width: 1199px) {
                line-height: 45px;
            }
        }

        .icon-wrap {
            @media screen and (max-width: 991px) {
                display: inline-block;
                width: 45px;
                height: 2.8125em;
                position: absolute;
                left: 0;
                bottom: 0;
                line-height: 2.375em;
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 100%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 5px 2.8125em 0;
                    border-color: transparent #cccccc transparent transparent;
                }
            }
        }

    }

    &__content {
        width: 350px;
        height: 100%;
        padding: 10px 0 10px 10px;
        background-color: #ffffff;

        overflow-x: hidden;
        overflow-y: auto;

        @media screen and (max-width: 460px) {
            width: 275px;
        }
    }

    &__external-twits {
        padding-right: 15px;
    }

}