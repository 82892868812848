.filter {
    margin-bottom: 3.5em;
    //padding-bottom: 2.5em;
    border-bottom: 1px solid $bg-hr-line;
    font-size: 1em;



}

.date-filter {
    font-size: 1em;
    margin-left: -15px;
    margin-right: -15px;

    > div {
        float: left;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__from, &__to {
        width: 50%;
        .icons {
            position: absolute;
            top: 50%;
            right: .3em;
            @include transform(translateY(-50%));
            font-size: 2em;
            color: $color-text-blue;
            cursor: pointer;
        }
    }

    &__from {

    }

    &__to {

    }

    .form-group {
        input[type="text"] {
            font-size: 1em;
            &:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
    }

}