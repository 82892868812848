.btn-group {

    @media screen and (max-width: 991px) {
        text-align: center;
    }

    .left-btn {
        float: left;
        @media screen and (max-width: 991px) {
            float: none;
        }
    }

    .right-btn {
        float: right;
        @media screen and (max-width: 991px) {
            float: none;
        }
        > * {
            margin-right: 4em;
            @media screen and (max-width: 991px) {
                margin-right: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .btn {
        @media screen and (max-width: 991px) {
            margin-bottom: 2em;
        }
    }

    .btn-link {
        line-height: 2.4;
        @media screen and (max-width: 991px) {
            line-height: 1.25;
        }
    }

}