@import "main-logo";
@import "top-control";

@import "menu-list";
@import "main-nav";

@import "search";
@import "burger";

// Base header style
.main-header {
    position: relative;
    color: $color-header-text;

    .header-wrap {
        position: relative;
        z-index: 100;
        padding: 2em 0 0 0;
        background-image: url("../../../img/bg-header.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        font-size: 1.125em;

        @media screen and (max-width: 1199px) {
            font-size: 1em;
        }
        @media screen and (max-width: 991px) {
            font-size: 1.125em;
            padding-top: 0;
        }
    }

}