/*===================================================
 Fonts
===================================================*/

// ProbaPro
// Regular
@font-face {
	font-family: "ProbaPro";
	src: url("../../fonts/ProbaPro_Regular/ProbaPro_Regular.eot");
	src: url("../../fonts/ProbaPro_Regular/ProbaPro_Regular.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/ProbaPro_Regular/ProbaPro_Regular.woff") format("woff"),
	url("../../fonts/ProbaPro_Regular/ProbaPro_Regular.woff2") format("woff2"),
	url("../../fonts/ProbaPro_Regular/ProbaPro_Regular.ttf") format("truetype"),
	url("../../fonts/ProbaPro_Regular/ProbaPro_Regular.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 400;
}

// Medium
@font-face {
	font-family: "ProbaPro";
	src: url("../../fonts/ProbaPro_Medium/ProbaPro_Medium.eot");
	src: url("../../fonts/ProbaPro_Medium/ProbaPro_Medium.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/ProbaPro_Medium/ProbaPro_Medium.woff") format("woff"),
	url("../../fonts/ProbaPro_Medium/ProbaPro_Medium.woff2") format("woff2"),
	url("../../fonts/ProbaPro_Medium/ProbaPro_Medium.ttf") format("truetype"),
	url("../../fonts/ProbaPro_Medium/ProbaPro_Medium.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 500;
}

// SemiBold
@font-face {
	font-family: "ProbaPro";
	src: url("../../fonts/ProbaPro_SemiBold/ProbaPro_SemiBold.eot");
	src: url("../../fonts/ProbaPro_SemiBold/ProbaPro_SemiBold.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/ProbaPro_SemiBold/ProbaPro_SemiBold.woff") format("woff"),
	url("../../fonts/ProbaPro_SemiBold/ProbaPro_SemiBold.woff2") format("woff2"),
	url("../../fonts/ProbaPro_SemiBold/ProbaPro_SemiBold.ttf") format("truetype"),
	url("../../fonts/ProbaPro_SemiBold/ProbaPro_SemiBold.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 600;
}

// Bold
@font-face {
	font-family: "ProbaPro";
	src: url("../../fonts/ProbaPro_Bold/ProbaPro_Bold.eot");
	src: url("../../fonts/ProbaPro_Bold/ProbaPro_Bold.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/ProbaPro_Bold/ProbaPro_Bold.woff") format("woff"),
	url("../../fonts/ProbaPro_Bold/ProbaPro_Bold.woff2") format("woff2"),
	url("../../fonts/ProbaPro_Bold/ProbaPro_Bold.ttf") format("truetype"),
	url("../../fonts/ProbaPro_Bold/ProbaPro_Bold.svg#svgFontName") format('svg');
	font-style: normal;
	font-weight: 700;
}