.main-nav {
    //position: relative;
    //color: $color-text-img;

    @media screen and (max-width: 991px) {
        display: none;
        position: absolute;
        width: 100%;
        top: 4em;
        left: 0;
        background-color: $bg-header-mobile;
        border-top: 1px solid $border-top-menu-mobile;
    }
    @media screen and (max-width: 460px) {
        top: 3.7em;
    }

    &__list {
        display: table;
        width: 100%;

        padding: 0 1em;

        @media screen and (max-width: 1199px) {
            padding: 0 .75em;
        }
        @media screen and (max-width: 991px) {
            display: block;
            padding: 2em 0 3em 0;
        }
    }

    &__item {
        display: table-cell;
        vertical-align: top;
        text-align: center;

        @include transition(all, .2s, ease-in-out);

        @media screen and (max-width: 991px) {
            position: relative;
            display: block;
            text-align: left;
        }

        &:first-child {
            text-align: left;
            .main-nav__link {
                padding-left: 1.5em;
                @media screen and (max-width: 1199px) {
                    padding-left: .75em;
                }
            }
        }

        &:hover {
            > .main-nav__link {
                &:hover {
                    color: $color-header-text-hover;
                }
            }
        }
        
        &.active {
            background-color: $bg-header-sub-menu;

            @media screen and (max-width: 991px) {
                background-color: inherit;
            }

            > .main-nav__link {
                @media screen and (max-width: 991px) {
                    color: $color-header-text-hover;
                    background-color: $bg-header-sub-menu;
                }
            }

            &:hover {
                color: inherit;
            }
        }

        &_left-link {
            position: absolute;
            top: 7em;
            left: 2.5em;
            text-align: left;

            @media screen and (max-width: 1199px) {
                left: 1.5em;
            }
            @media screen and (max-width: 991px) {
                position: relative;
                top: auto;
                left: auto;
                margin: 1.5em 0;
                padding: 1.5em 0 1.5em 15px;
                &::before, &::after {
                    content: '';
                    display: block;
                    width: 6em;
                    height: 1px;
                    position: absolute;
                    left: 15px;
                    background-color: rgba(255,255,255,.25);
                }
                &::before {
                    top: 0;
                }
                &::after {
                    bottom: 0;
                }
            }
            .btn-link {
                @media screen and (max-width: 991px) {
                    font-size: 1.22222222em;
                }
            }
        }

        &_social {
            padding-top: .9em;
            white-space: nowrap;
            @media screen and (max-width: 991px) {
                padding: 0 0 0 15px;
                margin: 2em 0;
            }
            a {
                display: inline-block;
                vertical-align: top;
                margin-right: 0.5em;

                color: $color-header-social;

                @media screen and (max-width: 1199px) {
                    margin-right: 0.5em;
                }
                @media screen and (max-width: 991px) {
                    font-size: 1.22222222em;
                }
                &:hover {
                    color: $color-header-text-hover;
                }
                &:last-child {
                    margin-right: .5em;
                }
            }
        }

        &_search-btn {
            padding-top: .85em;
            //padding-left: .5em;
            padding-right: .5em;
            font-weight: 500;
            @media screen and (max-width: 991px) {
                display: none;
            }
            .btn-link {
                color: $color-header-text;
                &.left-icon {
                    padding-left: 1.8em;
                }
                .icons {
                    font-size: 1.6em;
                }
            }
        }

        &_gov {
            position: absolute;
            top: 2em;
            left: 2.5em;
            text-align: left;

            @media screen and (max-width: 1199px) {
                left: 1.5em;
            }
            @media screen and (max-width: 991px) {
                position: static;
                padding-left: 15px;
            }
        }

    }

    &__link {
        display: inline-block;
        vertical-align: top;
        padding: 1.11111111em .5em 1.66666666em 0.5em;

        font-weight: 500;
        white-space: nowrap;

        @media screen and (max-width: 991px) {
            padding: .4em 1em .4em 15px;
            font-size: 1.22222222em;
            white-space: pre-wrap;
        }
    }

}





.main-nav {

    &__sub-nav {
        display: none;
        width: 100%;
        height: auto;
        padding: 1.66666666em 1em 1.66666666em 5%;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $bg-header-sub-menu;
        &_search {
            padding-left: 2.5em;
        }
        @media screen and (max-width: 991px) {
            position: static;
            top: auto;
            left: auto;
            margin-top: -1px;
            padding-left: 15px;
            padding-right: 45px;
        }
    }

    &__sub-nav-table {
        display: table;
        width: 100%;
        table-layout: fixed;

        @media screen and (max-width: 991px) {
            display: block;
        }
    }

    &__sub-nav-col {
        display: table-cell;
        vertical-align: top;
        padding-left: 1.5em;
        padding-right: 1em;
        border-left: 1px solid $color-header-secondary;
        text-align: left;

        @media screen and (max-width: 991px) {
            display: block;
            padding-left: 0;
            padding-right: 0;
            border-left: none;
        }
        > .menu-list {
            > .menu-item {
                @media screen and (max-width: 991px) {
                    position: relative;
                    &::after {
                        content: '';
                        display: block;
                        width: 2.77777777em;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: #cccccc;
                    }
                }

                &.open {
                    &::after {
                        background-color: $color-header-text-hover;
                    }
                    > .menu-link {
                        @media screen and (max-width: 991px) {
                            &::after {
                                background-color: $color-header-text-hover;
                            }
                        }
                    }
                }

                > .btn-sub-menu-open {
                    @media screen and (max-width: 991px) {
                        float: none;
                        width: 2em;
                        margin-left: 0;
                        padding-top: 0;
                        line-height: 2;
                        text-align: center;
                    }
                }
                > .menu-link {
                    @media screen and (max-width: 991px) {
                        max-width: calc(100% - 3.5em);
                        padding: 1em 0;
                        margin-right: .5em;
                        font-size: .77777777em;
                        text-transform: uppercase;
                        position: relative;
                        &::after {
                            content: '';
                            display: block;
                            width: 3.84615384em;
                            height: 1px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background-color: #cccccc;
                        }
                    }
                }
                .menu-list {
                    display: none;
                }
            }
        }
    }

}