// Burger
.burger {
    width: 2.5em;
    height: 1.8em;
    text-align: center;
    cursor: pointer;
    @include transition(all, .3s, ease-in-out);
    > span {
        position: relative;
        display: inline-block;
        //vertical-align: bottom;
        width: 1.875em;
        height: .125em;
        line-height: 2.5em;
        background-color: $color-header-text;
        &::before, &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            background-color: $color-header-text;
        }
        &::before {
            bottom: 450%;
        }
        &::after {
            top: 450%;
        }
    }
    &.active {
        > span {
            background-color: $color-header-text;
            @include transform(rotate(45deg));
            &::before, &::after {
                background-color: $color-header-text;
            }
            &::before {
                @include transform(rotate(-90deg) translate(-.55em, 0em));
            }
            &::after {
                content: none;
            }
        }
    }
}