.gallery-slider {

    &__head {
        margin-bottom: 2.5em;
        @media screen and (max-width: 767px) {
            margin-bottom: .5em;
        }
    }

    &__status {
        float: left;
        font-weight: 500;
        @media screen and (max-width: 767px) {
            float: none;
        }
        > * {
            display: inline-block;
            vertical-align: middle;
            padding: 0 2px;
        }
        .btn-nav {
            width: 1em;
            height: 1em;
            text-align: center;
            line-height: .4;
            color: $color-text-blue;
            cursor: pointer;
            .icons {
                font-size: .5em;
            }
        }
        .status-num, .separator {
            color: $color-text-grey;
        }
        .status-num {
            &.current {
                color: $color-text-blue;
            }
        }
    }

    &__download-img {
        float: right;
        text-align: right;
        font-weight: 500;
        @media screen and (max-width: 767px) {
            float: none;
            text-align: left;
        }
        .btn-link, .img-size {
            display: inline-block;
            vertical-align: middle;
        }
        .btn-link {
            @extend %round-link-icon;
            margin-right: 1em;
            .icons {
                &::before {
                    @include transform(translateX(-.22em));
                }
            }
        }
        .img-size {
            color: $color-text-grey;
        }
    }

    &__body {

    }

    &__slider-for {
        .slick-main__arrows {
            width: 5em;
            height: 100%;
            text-align: center;
            @media screen and (max-width: 767px) {
                width: 3em;
            }
            .icons {
                font-size: 2em;
                color: $color-text-img;
                opacity: .5;
            }
            &:hover {
                .icons {
                    opacity: .8;
                }
            }
        }
        .slick-main__arrows_prew {
            left: 0;
        }
        .slick-main__arrows_next {
            right: 0;
        }
    }

    &__footer {
        padding: 2.5em 0;
        @media screen and (max-width: 767px) {
            padding-top: .5em;
        }
        .hr-line {
            @media screen and (max-width: 767px) {
                margin: 1em 0;
            }
        }
    }

    &__social {
        color: $color-text-blue;
        font-weight: 500;
        > * {
            display: inline-block;
            vertical-align: middle;
        }
        .share-text {
            margin-right: 1.5em;
        }
        .social-link {
            margin: 0 1.5em;
            &.twitter {
                color: #338bcb;
            }
        }
    }

    &__slider-nav {
        margin-left: -6px;
        margin-right: -6px;
        .img-wrap {
            position: relative;
            margin: 0 6px;
            cursor: pointer;
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255,255,255,.5);
            }
            &:focus {
                border: none;
                outline: none;
            }
            &.slick-current {
                &::before {
                    content: none;
                }
            }
        }
    }

}