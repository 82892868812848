.left-side-section {

    &__content {
        .btn-wrap {
            @media screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 3.5em;
            }
        }
    }

}