.search-result {
    margin-bottom: 2.5em;

    &__info {
        .search-text {
            font-weight: 700;
        }
    }

    .filter {
        margin-bottom: 2.5em;
        .input-search-text {
            font-weight: 500;
            color: $color-text-blue;
            text-transform: uppercase;
        }
    }

    .list-common {
        margin-bottom: 0;
    }

}

.search-result {

    .btn-more-wrap {
        margin-bottom: 2.5em;
        text-align: right;
        .btn-link {
            padding-right: 2em;
            @extend %round-link-icon;
            .icons {
                top: 60%;
                &::before {
                    @include transform(translateX(-.2em));
                }
            }
        }
    }

    .btn-back-wrap {
        margin-top: 1em;
        text-align: left;
        .btn-link {
            padding-left: 2em;
            @extend %round-link-icon;
        }
    }

}