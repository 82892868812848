.page-contacts {
    margin-bottom: 3.125em;

    .contacts-map {
        margin: 0 0 3.125em 0;
        iframe {
            width: 100%;
            height: 500px;

            @media screen and (max-width: 767px) {
                height: 70vh;
            }
        }
    }

    .btn-wrap {
        text-align: left;
        .btn-text {
            margin-bottom: 2em;
            font-size: .875em;
            font-weight: 700;
            text-transform: uppercase;
        }
        .btn {
            width: 16.25em;
        }
    }

    .contacts-social {
        margin-bottom: 2.5em;
        .btn {
            display: block;
            width: 16.25em;
            margin-bottom: 1em;
            @media screen and (max-width: 767px) {
                margin: 0 auto 1em auto;
            }
            &.facebook {
                border-color: $color-text-blue;
                color: $color-text-blue;
            }
            &.twitter {
                border-color: #1389cc;
                color: #1389cc;
            }
            &.youtube {
                border-color: #ff6666;
                color: #ff6666;
            }
        }
    }

    .table {
        margin: 3.5em 0;
        th {
            padding-top: 2em;
            padding-bottom: 2em;
        }
        th, td {
            vertical-align: middle;
            text-align: center;
        }
    }

}