%title-icon {
    position: relative;
    text-indent: .6em;
    &::before {
        content: '';
        display: block;
        width: 1em;
        height: 1em;
        position: absolute;
        z-index: 9;
        top: .1em;
        left: 0;
        background-image: url("../../img/title-img.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .text {
        position: relative;
        z-index: 10;
    }
}


.page-title {
    margin-bottom: 1.25em;
    .title-h1, .title-h2, .title-h3 {
        font-weight: 400;
        line-height: 1.25;
    }

    .title-h1, .title-h2, .title-h3 {
        font-weight: 400;
        line-height: 1.25;
    }

    .title-h1 {
        margin-bottom: 1em;
        padding: 0 20%;
        font-size: 2.5em;
        text-align: center;
        @media screen and (max-width: 991px) {
            padding: 0;
            font-size: 1.875em;
        }
    }

    .title-h2 {
        @extend %title-icon;
        font-size: 2.5em;
        @media screen and (max-width: 991px) {
            font-size: 1.875em;
        }
    }

    .title-h3 {
        @extend %title-icon;
        font-size: 1.875em;
        @media screen and (max-width: 991px) {
            font-size: 1.5em;
        }
    }

    .title-date {
        margin-top: -1.25em;
        margin-bottom: 2.5em;
        font-weight: 500;
        color: $color-text-grey;
        text-align: center;
    }

}