
// CSS ACCORDION

// Accordion base layout
%accordion-base-layout {

	font-size: 1em;

	.accordion__item {
		margin-bottom: .8975em;
	}

	.accordion__head {
		position: relative;
		padding: 1.5em 1.25em;
		cursor: pointer;

		> * {
			margin: 0;
			padding: 0;
			font-size: inherit;
			color: inherit;
			font-weight: inherit;
			line-height: inherit;
		}

		.title {
			font-size: .75em;
			font-weight: 400;
			color: $color-text-blue;
			text-transform: uppercase;
			&.btn-link {
				@extend %round-link-icon;
				padding-left: 2.8em;
				vertical-align: baseline;
				.icons {
					font-size: 1.6em;
					&::before {
						font-size: .55em;
						@include transform(none);
						@include transition(all, .2s, ease-in-out);
					}
				}
			}
		}

	}

	.accordion__body {
		display: none;
		padding: 2em;
		@media screen and (max-width: 767px) {
			padding: 2em 0;
		}
		.f-text-block {
			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

}

// Theme - White-Gray
%theme-white-grey {

	.accordion__item {

		&.open {
			> .accordion__head {
				.title {
					&.btn-link {
						.icons {
							&::before {
								@include transform(rotate(180deg));
							}
						}
					}
				}
			}
		}

	}

	.accordion__head {
		background-color: $bg-grey;
	}

}

.accordion {
	@extend %accordion-base-layout;
	@extend %theme-white-grey;

	.accordion__body {
		.f-text-block {
			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}