
/* ===== Form Group ===== */

// Inputs text
.form-group {
    width: 100%;
    margin-bottom: 2em;
    position: relative;

    input[type="text"], input[type="email"], input[type="tel"], input[type="password"] {
        width: 100%;
        padding: .75em 1em;
        border: 1px solid $border-color-form;
        outline: none;
        background-color: #ffffff;
        font-size: 1em;
        @extend %placeholder-style;

        &:focus {
            outline: none;
            border-color: $border-active-input;

            -webkit-box-shadow: 0px 0px 0px 1px $border-active-input;
            -moz-box-shadow: 0px 0px 0px 1px $border-active-input;
            box-shadow: 0px 0px 0px 1px $border-active-input;
        }
    }

    textarea {
        @extend %placeholder-style;

        width: 100%;
        min-height: 10em;
        resize: none;
        padding: .6em .5em .7em 1em;
        border: 1px solid $border-color-form;
        outline: none;
        background-color: transparent;
        font-size: 1em;

        &:focus {
            outline: none;
            border-color: $border-active-input;

            -webkit-box-shadow: 0px 0px 0px 1px $border-active-input;
            -moz-box-shadow: 0px 0px 0px 1px $border-active-input;
            box-shadow: 0px 0px 0px 1px $border-active-input;
        }
    }

    .label-text {
        margin-bottom: 1.11111111em;
        font-size: 1.125em;
        font-weight: 500;
    }

    .secondary-text {
        position: absolute;
        top: 1.5em;
        left: 0;
        font-size: .875em;
        color: $color-text-secondary-form;
    }

    .error{
        color:red;
    }

    &.success, .success {
        color: green;
        border: 1px solid green;
        padding: 10px;
    }
}

// Select to boot DOM
.select-chosen-wrap {
    select {
        width: 100%;
        height: 2.875em;
    }
}

// Checkbox
.checkbox-wrap {
    width: 100%;
    margin-bottom: 2em;
    position: relative;
    padding: .6875em 0;

    input[type="checkbox"] {
        display: none;

        // Label text
        + label {
            display: inline-block;
            position: relative;
            margin-right: 1.875em;
            line-height: 1.5em;
            cursor: pointer;

            // Unchecked checkbox box border
            &::before {
                display: inline-block;
                width: 1.5em;
                height: 1.5em;
                content: '';
                border: 2px solid $color-text-base;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                vertical-align: top;
                margin-right: 15px;
                cursor: pointer;
                z-index: 10;
            }
        }

        // Checked checkbox box border
        &:checked + label {
            &::before {
                border-color: $color-text-blue;
                background-color: $color-text-blue;
            }

            // Checkmark
            &::after {
                width: .5em;
                height: 1em;
                border: .1875em solid $color-text-img;
                border-left: none;
                border-top: none;
                content: '';
                position: absolute;
                left: .5625em;
                bottom: .375em;
                //transform: rotatez(45deg);
                @include transform(rotateZ(45deg));
                cursor: pointer;
                z-index: 20;
            }
        }

        // Disabled checkbox not checked
        &:disabled + label {
            color: $color-text-secondary-form;
            cursor: auto;
            &::before {
                border-color: $disabled-form;
                background-color: $disabled-form;
                cursor: auto;
            }
        }

        // Disabled checkbox checked
        &:disabled:checked + label {
            color: $color-text-secondary-form;
            cursor: auto;
            &::before {
                border-color: $color-text-secondary-form;
                background-color: $color-text-secondary-form;
                cursor: auto;
            }
            &::after {
                cursor: auto;
            }
        }

        // Focus and active checkbox not checked
        &:focus + label, &:active + label {
            &::before {
                border-color: $border-active-input;
            }
        }

        // Focus and active checkbox checked
        &:checked:focus + label, &:checked:active + label {
            &::before {
                border-color: $border-active-input;
            }
        }
    }

}