.header-search-wrap {
    display: none;
    width: 100%;
    height: auto;
    padding: 2.5em 1em 2.5em 2.5em;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $bg-header-sub-menu;
    font-size: .88888889em;
    @media screen and (max-width: 1199px) {
        top: 4em;
        padding-left: 1.5em;
        border-top: 1px solid rgba(255,255,255,.25);
    }
    @media screen and (max-width: 991px) {
        padding: 1em 15px 1.8em 15px;
        top: 4em;
        border-top: 1px solid rgba(255,255,255,.25);
    }
    @media screen and (max-width: 460px) {
        top: 3.7em;
    }
    .close-search {
        display: block;
        width: 2em;
        height: 2em;
        position: absolute;
        top: 50%;
        right: 1em;
        @include transform(translateY(-50%));
        font-size: .88888888em;
        line-height: 1.5em;
        text-align: center;
        color: #969697;
        cursor: pointer;
        @media screen and (max-width: 991px) {
            top: 2.5em;
        }
    }
    .label-wrap {
        float: left;
        width: 13%;
        height: 4.3em;
        padding-top: 1.6em;
        padding-right: .5em;

        font-size: .66666666em;
        color: #9C9C9C;
        font-weight: 400;
        text-transform: uppercase;
        text-align: left;
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            height: auto;
            margin-bottom: 2em;
        }
    }
    .form-group {
        float: left;
        width: 40%;
        margin-bottom: 0;
        margin-right: 2.5%;
        input[type="text"] {
            background-color: #5a6a7c;
            border-color: #5a6a7c;
            color: $color-text-img;
        }
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            margin-bottom: 1.44444444em;
        }
    }
    .btn-wrap {
        float: left;
        width: 20%;
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            text-align: center;
            padding: 10px 0;
        }
        .btn {
            white-space: nowrap;
            width: 95%;
            background-color: $color-header-text-hover;
            color: $color-text-base;
            border-color: $color-header-text-hover;
            @media screen and (max-width: 991px) {
                max-width: 15em;
            }
            &.blue{
                background-color: $bg-btn-blue;
                border-color: $bg-btn-blue;
                color: $color-text-img;
            }
        }
        .right{
            float: right;
            @media screen and (max-width: 991px) {
                float: none;
            }
        }
    }
}