.about-page {

    .external-text-wrap {
        margin-top: 2em;
        margin-bottom: 2.5em;
        padding-right: 5%;
        > * {
            padding-left: 1.2em;
        }
    }

    .page-title {
        .title-h3 {
            margin-bottom: 1.5em;
        }
    }

}