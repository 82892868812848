.news-list {
    @media screen and (max-width: 991px) {
        margin-bottom: 2.5em;
    }
    .news-item {
        position: relative;
        padding-left: 13em;
        margin-bottom: 2.5em;

        @media screen and (max-width: 767px) {
            padding-left: 9em;
        }

        &__img {
            display: block;
            width: 11.5em;
            position: absolute;
            top: 0;
            left: 0;

            @media screen and (max-width: 767px) {
                width: 8em;
            }
            a {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                font-size: 0;
            }
            img {
                width: 100%;
                height: auto;
            }
        }

        &__text {
            position: relative;
            padding-top: 1.5em;
            padding-bottom: 1.25em;
            height: 7.85em;

            @media screen and (max-width: 767px) {
                height: 5.8em;
                padding-top: 0;
            }
        }

        &__remark-text {
            position: absolute;
            top: -.3em;
            left: 0;
            color: $color-remark;
            line-height: 1;
            .icons {
                margin-right: .5em;
            }
            > * {
                vertical-align: middle;
            }
            span {
                font-size: .625em;
                font-weight: 700;
                text-transform: uppercase;
                @media screen and (max-width: 991px) {
                    font-size: .75em;
                }
            }
        }

        &__descr {
            position: relative;
            height: 4.5em;
            overflow: hidden;
            color: $color-text-blue;
            line-height: 1.5;
            font-weight: 500;
            @media screen and (max-width: 991px) {
                height: 3.75em;
                line-height: 1.25;
                font-size: 1.125em;
            }
            a {
                display: inline-block;
                vertical-align: top;
                width: 100%;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__date {
            position: absolute;
            left: 0;
            bottom: 0;

            @extend %date;

            @media screen and (max-width: 991px) {
                font-size: 1.125em;
            }
        }

        &.remark {
            .news-item__descr {
                @media screen and (max-width: 767px) {
                    height: 2.5em;
                }
            }
            .news-item__text {
                @media screen and (max-width: 767px) {
                    padding-top: 1.25em;
                }
            }
        }
    }
}