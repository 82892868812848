.slider-wrap {
    overflow: hidden;
    &.big-slider {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        background-color: rgba(0,0,0,.8);
        .slick-main {
            position: absolute;
            width: 80%;
            height: auto;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%,-50%));
            .slick-slide {
                max-height: 90vh;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                img {
                    opacity: 0;
                }
            }
            &__arrows {
                top: 45%;
                font-size: 2em;
                color: $color-text-img;
            }
        }
        .close-full-screen-slider {
            width: 2.5em;
            height: 2.5em;
            position: absolute;
            z-index: 1001;
            top: 0;
            right: 0;
            background-color: rgba(255,255,255,.6);

            font-size: 2em;
            line-height: 2;
            color: $color-text-grey;
            text-align: center;
            cursor: pointer;

            @include transition(all, .2s, ease-in-out);
            &:hover {
                background-color: rgba(255,255,255,.8);
                color: $color-text-base;
            }
        }
    }
}

.slick-main {
    padding-bottom: 1.5em;
    white-space: nowrap;

    &__item {
        display: inline-block;
        vertical-align: top;
        width: 0;
        font-size: 0;
        &:first-child {
            width: auto;
        }
    }

    &__dots {
        position: absolute;
        right: 0;
        bottom: 0;
        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 5px;
            &:last-child {
                margin-right: 0;
            }
            &.slick-active {
                button {
                    background-color: $bg-active-dots;
                }
            }
            button {
                display: inline-block;
                vertical-align: top;
                width: 9px;
                height: 9px;
                padding: 5px;
                background-color: #f5f7fa;
                border: 1px solid rgba(74,74,73,.21);
                @include border-radius(50%);

                font-size: 0;
                line-height: 0;
                &:focus {
                    outline: none;
                }
            }
        }
    }

    &__arrows {
        position: absolute;
        z-index: 999;
        width: 2em;
        height: 2em;
        top: 50%;
        @include transform(translateY(-50%));

        border: none;
        outline: none;
        background-color: transparent;

        line-height: .5;
        text-align: center;

        &_prew {
            left: -3em;
        }

        &_next {
            right: -3em;
        }

        &.slick-disabled {
            opacity: 0;
            visibility: hidden;
            cursor: auto;
        }
    }

}