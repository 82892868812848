.news-post {
    margin-bottom: 2em;

    &__media {
        float: left;
        width: 18.75em;
        height: auto;
        margin: 0 1.875em 1em 0;

        @media screen and (max-width: 767px) {
            float: none;
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
        }
        .btn-wrap {
            margin-top: -1.5em;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
        .slick-main {
            padding-bottom: 2.5em;
        }
    }

    &__text {
        margin-bottom: 3em;
        @media screen and (max-width: 767px) {
            margin-top: -1.8em;
        }
    }

    &__date {
        @extend %date;
        margin-bottom: 3.125em;
        .bottom-line {
            &::after {
                bottom: -.25em;
                background-color: $color-yellow;
            }
        }
    }

    &__footer {
        .brn-wrap {
            float: left;
            @media screen and (max-width: 767px) {
                float: none;
                margin-bottom: 2em;
            }
        }
        .social {
            float: right;
            padding-top: .25em;
            white-space: nowrap;
            font-weight: 500;
            color: $color-text-blue;
            @media screen and (max-width: 767px) {
                float: none;
            }
            > * {
                display: inline-block;
                vertical-align: middle;
            }
            .text {
                margin-right: 1em;
            }
            .social-list {
                a {
                    color: $color-text-blue;
                    &:hover {
                        color: $color-text-base;
                    }
                }
            }
        }
    }

}