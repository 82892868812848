.side-menu {
    font-size: 1em;
    margin-bottom: 2.5em;

    @media screen and (max-width: 991px) {
        display: none;
    }

    > .menu-list {
        > .menu-item {
            position: relative;
            width: auto;
            &::after {
                content: '';
                display: block;
                width: 3.125em;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $color-secondary;
            }
            &:first-child {
                > .menu-link {
                    padding-top: 0;
                }
                > .btn-sub-menu-open {
                    line-height: .5;
                    vertical-align: top;
                }
                > .search-count {
                    padding-top: 0;
                }
            }
            &.open {
                &::after {
                    background-color: $color-text-blue;
                }
                > .menu-link {
                    &::after {
                        background-color: $color-text-blue;
                    }
                }
            }
            > .menu-link {
                position: relative;
                max-width: calc(100% - 3.5em);
                width: auto;
                padding: 1em 0;
                font-size: .875em;
                text-transform: uppercase;
                &::after {
                    content: '';
                    display: block;
                    width: 3.57142857em;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: $color-secondary;
                }
            }
            > .btn-sub-menu-open,
            > .search-count {
                float: none;
                width: 2em;
                margin-left: 0;
                padding-top: 0;
                line-height: 2.3;
                text-align: center;
            }
            > .search-count {
                display: inline-block;
                vertical-align: top;
                width: auto;
                line-height: 1.25;
                padding-top: 1em;
                margin-left: 5px;
                font-size: .875em;
                color: $color-menu-side-search-count;
            }
        }
    }

    .menu-list {

    }

    .menu-item {
        &.open {
            > .menu-link {
                color: $color-text-blue;
            }
            > .btn-sub-menu-open {
                color: $color-text-blue;
            }
            > .menu-list {
                display: block;
            }
        }
        > .menu-list {
            display: none;
            padding: 1em 0 1em 1.5em;
        }
    }

    .menu-link {
        padding: .5em 0;
        font-weight: 500;
        width:calc(100% - 25px);
        &:hover {
            color: $color-text-blue;
        }
    }

    .btn-sub-menu-open {
        display: inline-block;
        padding-top: 0.4em;
        &:hover {
            color: $color-text-blue;
        }
    }

    .main-nav__sub-nav {
        position: static;
        top: auto;
        left: auto;
        padding: 1em 0 1em 1.5em;
        background-color: initial;
    }

    .main-nav__sub-nav-table {
        display: block;
    }

    .main-nav__sub-nav-col {
        display: block;
        vertical-align: initial;
        padding-left: 0;
        padding-right: 0;
        border-left: none;
        text-align: left;
    }

}