// Main style select chosen

.select-chosen-wrap {

	@media screen and (max-width: 991px) {
		margin-bottom: 1.5em;
	}

	select {
		width: 100%;
		height: 2.875em;
	}

	.chosen-container {
		font-size: 1em;
		.chosen-single,.chosen-choices {
			width: 100%;
			height: auto;
			padding: .5em 0 .5em 1em;
			border: 1px solid $border-color-form;
			outline: none;
			background: none;
			background-color: transparent;
			@include border-radius(0);
			-webkit-box-shadow: none;
			-moz-box-shadow:  none;
			box-shadow:  none;
			font-size: 1em;
			line-height: 1.4;
			color: $color-text-secondary-form;
			&.chosen-default {
				span {
					color: $color-text-secondary-form;
					@include respond-to(xl) { line-height: 1.5; }
				}
			}
			& > span {
				line-height: 1.8;
				margin-right: 2.5em;
			}
			div {
				width: 2em;
			}
			li.search-choice{
				border-color:#6d727c;
				border-radius: 0;
				background-image:none;
				background-color: #f5f7fa;
			}
		}
		.chosen-drop {
			margin-top: 1px;
			border: 1px solid $color-secondary;
			border-top: none;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
		}
		.chosen-results {
			margin: 0;
			padding-left: 0;
			li {
				padding: .8em 1em;
				@include transition(all, .2s, ease-in-out);
				&.highlighted {
					background: none;
					background-color: $border-active-input;
					color: $color-text-img;
				}
			}
		}
		&.chosen-container-active {
			.chosen-single {
				border-color: $border-active-input;
				-webkit-box-shadow: 0px 0px 0px 1px $border-active-input;
				-moz-box-shadow: 0px 0px 0px 1px $border-active-input;
				box-shadow: 0px 0px 0px 1px $border-active-input;
			}
		}
	}



	.chosen-container-single .chosen-single abbr {
		background: url("../../../vendor/chosen-js/chosen-sprite-new.png") -42px 1px no-repeat;
	}
	.chosen-container-single .chosen-single div b {
		background: url("../../../vendor/chosen-js/chosen-sprite-new.png") no-repeat -95px .35em;
	}
	.chosen-container-active .chosen-single div b {
		background-position: -174px .35em;
	}
	.chosen-container-active.chosen-with-drop .chosen-single div b {
		background-position: -137px .35em;
		//@include respond-to(lg) { padding-top: 145px; }
	}
	.chosen-container-single .chosen-search input[type="text"] {
		background: url("../../../vendor/chosen-js/chosen-sprite-new.png") no-repeat 100% -20px;
	}
	.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
		background: url("../../../vendor/chosen-js/chosen-sprite-new.png") -42px 1px no-repeat;
	}
	.chosen-rtl .chosen-search input[type="text"] {
		background: url("../../../vendor/chosen-js/chosen-sprite-new.png") no-repeat -30px -20px;
	}

}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
	.chosen-rtl .chosen-search input[type="text"],
	.chosen-container-single .chosen-single abbr,
	.chosen-container-single .chosen-single div b,
	.chosen-container-single .chosen-search input[type="text"],
	.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
	.chosen-container .chosen-results-scroll-down span,
	.chosen-container .chosen-results-scroll-up span {
		background: url("../../../vendor/chosen-js/chosen-sprite-new.png") no-repeat -95px .25em !important;
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
	.chosen-container-active.chosen-with-drop .chosen-single div b {
		background-position: -137px .25em !important;
	}
}

@media only screen and (min-width: 1920px) {
	.select-chosen-wrap .chosen-container .chosen-single.chosen-default span {
		line-height: 1.8;
	}
}

