#main_content_modal {
    color: #337ab7;
    align-content: center;
    text-align: center;
    top: 20px;
    position: relative;
    z-index: 999;
    background: white;
    padding: 20px;
    width:93%;
    margin:0 auto;
    
}
