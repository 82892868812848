.refunds {

    .refunds-form-wrap {
        width: 100%;
        max-width: 860px;
        .bot-protection {
            margin-bottom: 3em;
        }
        .btn-group {
            .btn {
                padding-left: 4em;
                padding-right: 4em;
                @media screen and (max-width: 991px) {
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }
        }
    }
}
    .vat_error {
    color:red;
    display:none;
    }
