.menu-list {
    .menu-item {
        .menu-list {
            @media screen and (max-width: 991px) {
                padding: 1em 0 1em 1.5em;
            }
        }
        &.open {
            > .btn-sub-menu-open {
                color: $color-header-text-hover;
                .icons {
                    @include transform(rotate(180deg));
                }
            }
            > .menu-link {
                color: $color-header-text-hover;
            }
        }
    }
    .menu-link {
        display: inline-block;
        vertical-align: top;
        padding: .3em 0;
        @media screen and (max-width: 991px) {
            font-size: .88888888em;
        }
        &:hover {
            color: $color-header-text-hover;
            @media screen and (max-width: 991px) {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
    .btn-sub-menu-open {
        display: none;
        float: left;
        vertical-align: middle;
        width: 1.5em;
        margin-left: -1.7em;
        padding-top: .2em;
        line-height: 1;
        text-align: left;
        cursor: pointer;
        @media screen and (max-width: 991px) {
            display: inline-block;
        }
        .icons {
            @include transition(all, .2s, ease-in-out);
        }
    }
}