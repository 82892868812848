.nav-btns {
    padding-top: 2.75em;
    font-size: 1em;

    @media screen and (max-width: 1199px) {
        font-size: .875em;
    }
    @media screen and (max-width: 991px) {
        padding-top: 0;
        font-size: 1em;
    }
    @media screen and (max-width: 460px) {
        font-size: .875em;
    }

    &__list {
        display: table;
        width: 100%;
        table-layout: fixed;
        @media screen and (max-width: 991px) {
            position: relative;
            &::after {
                content: '';
                display: table;
                clear: both;
            }
        }
        @media screen and (max-width: 991px) {
            display: block;
            table-layout: auto;
            border-top: 1px solid $color-header-secondary;
            border-right: 1px solid $color-header-secondary;
        }
    }

    &__item {
        position: relative;
        display: table-cell;
        height: 3.77777778em;
        vertical-align: middle;
        padding-right: 10px;

        border-top: 1px solid $border-t-b-nav-btns;
        border-bottom: 1px solid $border-t-b-nav-btns;
        border-left: 1px solid $color-header-secondary;
        @media screen and (max-width: 991px) {
            float: left;
            display: block;
            width: 50%;
            height: 7.77777778em;
            padding-right: 10px;
            padding-left: 10px;
            border-top: none;
            border-color: $color-header-secondary;
        }
        &:last-child {
            &::before, &::after {
                content: none;
            }
        }
        &::before, &::after {
            content: '';
            display: block;
            width: 10px;
            height: 1px;
            position: absolute;
            right: 0;
            background-color: white;
            @media screen and (max-width: 991px) {
                content: none;
            }
        }
        &::before {
            top: -1px;
        }
        &::after {
            bottom: -1px;
        }
        &_spending {
            display: none;
            @media screen and (max-width: 991px) {
                display: table-cell;
            }
        }
    }

    &__link {
        position: relative;
        display: table;
        width: 100%;
        height: 100%;
        padding: 0 0 0 3.5em;
        font-size: 1.125em;
        font-weight: 500;
        color: $color-text-blue;
        text-align: left;
        @media screen and (max-width: 991px) {
            padding-left: 0;
            text-align: center;
        }
        &:hover {
            color: $color-text-blue;
            text-decoration: underline;
        }
        .icons {
            position: absolute;
            top: 50%;
            left: 10px;
            @include transform(translateY(-50%));
            font-size: 2.5em;
            color: $color-btns-icon;
            @media screen and (max-width: 991px) {
                position: static;
                @include transform(none);
            }
        }
        .spending {
            display: inline-block;
            width: 2.5em;
            height: 2.5em;
            position: absolute;
            top: 50%;
            left: 10px;
            @include transform(translateY(-50%));
            background-image: url("../../../img/header-btns-spending.png");
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: 991px) {
                position: static;
                @include transform(none);
            }
        }
        .text {
            @media screen and (max-width: 991px) {
                display: inline-block;
                width: 100%;
                margin-top: .5em;
            }
        }
    }

    &__link-content {
        display: table-cell;
        vertical-align: middle;
    }

}