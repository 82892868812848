/*===================================================
 General Style
===================================================*/

@import "reset";
@import "../components/table";

body, html {
	width: 100%;
	min-height: 100%;
}

body {
	position: relative;
	font-family: $font-family-base;
	font-size: $font-size-base + px;
	line-height: $line-height-base;
	font-weight: 400;
	color: $color-text-base;
	text-align: left;
}

a {
	color: inherit;
	@include transition(all, .2s, ease-in-out);
	text-decoration: none;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
	&:focus {
		//outline: none;
		//border: none;
		color: inherit;
		text-decoration: none;
	}
}

.clearfix::after {
	content: "";
	clear: both;
	display: table;
}

.icons {
	vertical-align: -webkit-baseline-middle;
	line-height: 1em;
	display: inline-block;
}

// Structure page
.height-page {
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 0 0 360px 0;
	overflow: hidden;
}

.container-fluid {
    width: 96%;
	max-width: 1266px;
	margin: 0 auto;
	@media screen and (max-width: 991px) {
		width: 100%;
	}
	&.full-screen-mob {
		@media screen and (max-width: 991px) {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.full-screen-mob {
	@media screen and (max-width: 991px) {
		width:auto;
		margin-left: -15px;
		margin-right: -15px;
	}
}

.page-content {
	padding-top: 0;
	padding-bottom: 2.5em;
	overflow: hidden;

	@media screen and (max-width: 991px) {
		padding-top: 2em;
	}

	section {
		padding: 2.5em 0;
		&.no-pb {
			padding-bottom: 0;
		}
		&.no-pt {
			padding-top: 0;
		}
	}
}

.full-width-tablet {
	@include respond-to(md) { width:auto;margin-left: -5.5%;margin-right: -5.5%; }
	@include respond-to(sm) { width:auto;margin-left: -15px;margin-right: -15px; }
}

.full-width-mob {
	@include respond-to(sm) { width:auto;margin-left: -15px;margin-right: -15px; }
	> .row {
		@include respond-to(sm) { margin-left: 10px;margin-right: 10px; }
	}
}

.width-point {
    width: 0;
    height: 0;
	position: absolute;
    left: -2000px;
    bottom: 0;
    opacity: 0;
}

.hr-line {
	margin: 2.1875em 0;
	width: 100%;
	height: 1px;
	background-color: $bg-hr-line;
	&.mar1-5 {
		margin: 1.5em 0;
	}
}

// END Structure page
/*=================================*/
// Blocks style


.pad-top-1-5 {
	padding-top: 1.5em;
}

.br-991 {
	display: none;
	@media screen and (max-width: 991px) {
		display: block;
	}
}


// END Blocks style
/*=================================*/
// Text Style

.external-text-wrap {
	font-size: 1em;
	font-weight: 500;
	line-height: 1.5;

	&.pad-l-1-2 {
		padding-left: 1.2em;
	}

	&.image-full-size{
		img {
			width: 100%;
			height: auto;
		}
	}

	.sub-title, h3, h2 {
		margin: 0.5em 0 0.8em 0;
		font-size: .875em;
		font-weight: 700;
	}

	.text, p {
		margin: 0 0 1em 0;
	}

	a {
		color: $color-text-blue;
		font-weight: 500;

		&:hover {
			text-decoration: underline;
		}
		&[href$=".doc"],
		&[href$=".rtf"],
		&[href$=".docx"],
		&[href$=".jpg"],
		&[href$=".tiff"],
		&[href$=".ppt"],
		&[href$=".pdf"],
		&[href$=".xls"],
		&[href$=".xlsx"],
		&[href$=".zip"],
		&[href$=".rar"],
		&[href$=".7z"]{
			padding-left: 2em;

			background-repeat: no-repeat;
			background-position: left center;
			background-size: contain;
		}
		&[href$=".doc"], &[href$=".docx"], &[href$=".rtf"] {
			background-image: url("../../icons/file-doc.svg");
			&:hover {
				background-image: url("../../icons/file-doc-hover.svg");
			}
		}
		&[href$=".jpg"] {
			background-image: url("../../icons/file-jpg.svg");
			&:hover {
				background-image: url("../../icons/file-jpg-hover.svg");
			}
		}
        &[href$=".tiff"] {
          padding-left: 2.5em;
          background-image: url("../../icons/file-tiff.svg");
          &:hover {
            background-image: url("../../icons/file-tiff-hover.svg");
          }
        }
		&[href$=".ppt"] {
			background-image: url("../../icons/file-ppt.svg");
			&:hover {
				background-image: url("../../icons/file-ppt-hover.svg");
			}
		}
		&[href$=".pdf"] {
			background-image: url("../../icons/file-pdf.svg");
			&:hover {
				background-image: url("../../icons/file-pdf-hover.svg");
			}
		}
		&[href$=".xls"], &[href$=".xlsx"] {
			background-image: url("../../icons/file-xls.svg");
			&:hover {
				background-image: url("../../icons/file-xls-hover.svg");
			}
		}
		&[href$=".zip"], &[href$=".7z"] {
			background-image: url("../../icons/file-zip.svg");
			&:hover {
				background-image: url("../../icons/file-zip-hover.svg");
			}
		}
		&[href$=".rar"] {
			padding-left: 2.5em;
			background-image: url("../../icons/file-rar.svg");
			&:hover {
				background-image: url("../../icons/file-rar-hover.svg");
			}
		}

		&.no-icon{
			padding-left: auto;
			&,&:hover {
				background-image: none;
			}
		}
	}

	b, strong {
		font-weight: 600;
	}

	ul {
		padding-left: 1em;
		list-style-type: circle;
		li {
			margin: .5em 0;
		}
	}

	ol {
		list-style: decimal;
		list-style-position: inside;
	}

	hr {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	table {
		@extend %default-table;
	}

	.list-common {
		list-style: none;
		margin: 2em 0;
		.list-common__item-text {
			margin-bottom: .35em;
		}
	}
}

.medium {
	font-weight: 500;
}

.bold {
	font-weight: 700;
}

.upper-text {
	text-transform: uppercase;
}

.lower-text {
	text-transform: lowercase;
}

.text-right-desct {
	text-align: right;
	@media screen and (max-width: 991px) {
		text-align: center;
	}
}

// END Text Style
/*=================================*/

.bottom-line {
	position: relative;
	&::after {
		content: '';
		display: block;
		width: 5em;
		height: 1px;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: $color-secondary;
	}
}

.img-wrap {
	img {
		width: 100%;
		height: auto;
	}
}

.hidden{
	display: none;
}
